import React, { type Dispatch, type SetStateAction, useState } from "react";
import { type DateRangeSelection } from "@/types/dashboard";
import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";
import { DateStringFormat } from "@/types/callbotRecord";

type Props = {
  selectedDateRange: DatePickerFilter;
  setSelectedDateRange: Dispatch<SetStateAction<DatePickerFilter>>;
};

function CustomDateRangePicker({
  selectedDateRange,
  setSelectedDateRange,
}: Props): React.JSX.Element {
  const [openDateRange, setOpenDataRange] = useState(false);

  const handleOnDateRangeClick = () => {
    setOpenDataRange(!openDateRange);
  };
  const handleDateRangeChange = (item: DateRangeSelection) => {
    setSelectedDateRange(item);
  };

  return (
    <div className="w-[250px]">
      {openDateRange ? (
        <div className="absolute right-8 z-[1] flex flex-col items-end border border-slate-300 bg-white p-6">
          <XMarkIcon
            className="mb-2 mt-[-5px] h-6 w-6 cursor-pointer text-trajector-blue"
            onClick={handleOnDateRangeClick}
          />
          <DateRangePicker
            ranges={[selectedDateRange]}
            onChange={(item) =>
              handleDateRangeChange(item.selection as DateRangeSelection)
            }
          />
        </div>
      ) : selectedDateRange && selectedDateRange?.startDate ? (
        <div
          className="w-2/5‰ flex cursor-pointer flex-row items-center justify-between bg-white px-3 text-secondary-600"
          onClick={handleOnDateRangeClick}
        >
          <p className="pr-2">
            {format(selectedDateRange.startDate, DateStringFormat)} |{" "}
            {format(selectedDateRange.endDate, DateStringFormat)}
          </p>
          <CalendarDaysIcon className="h-6 w-6 text-trajector-blue" />
        </div>
      ) : (
        <div
          className="flex w-1/3 cursor-pointer flex-row items-center justify-between bg-white px-3 text-secondary-600"
          onClick={handleOnDateRangeClick}
        >
          <p>Select date range</p>
          <CalendarDaysIcon className="h-6 w-6 text-trajector-blue" />
        </div>
      )}
    </div>
  );
}

export default CustomDateRangePicker;
