/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type ColumnDef } from "@tanstack/react-table";
import { useRouter } from "next/router";
import { type UserStats } from "@/types/dashboard";
import { format } from "date-fns";
import { DateStringFormat } from "@/types/callbotRecord";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";
import React from "react";
import { HeaderAlign } from "@/types/table";

export function useUserColumns(
  selectedDateRange: DatePickerFilter
): ColumnDef<UserStats>[] {
  const { push } = useRouter();

  return [
    {
      accessorKey: "user_name",
      id: "user_name",
      header: "Name",
      cell: (info) => (
        <a
          className="ml-3 cursor-pointer text-trajector-blue"
          data-testid="user-column-name"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            push({
              pathname: "/assigned",
              query: {
                userId: info.row.original.user_id,
                from: format(selectedDateRange.startDate, DateStringFormat),
                to: format(selectedDateRange.endDate, DateStringFormat),
              },
            });
          }}
        >
          {info.getValue() as string}
        </a>
      ),
    },
    {
      accessorKey: "total_assigned",
      id: "total_assigned",
      header: "Assigned",
      meta: { headerAlign: HeaderAlign.RIGHT },
      cell: (info) => (
        <div className="text-right">{info.getValue() as string}</div>
      ),
    },
    {
      accessorKey: "",
      id: "progress",
      header: "Progress",
      meta: { headerAlign: HeaderAlign.CENTER },
      cell: ({ row }) => {
        const progress = Math.round(
          ((row.original.total_closed + row.original.total_invoice_pending) *
            100) /
            row.original.total_assigned
        );

        return (
          <div className="w-full rounded bg-gray-200 shadow">
            <div
              className="bg-blue-600 py-1 text-center text-xs leading-none text-white"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        );
      },
    },
    {
      accessorKey: "total_ready_for_review",
      id: "total_ready_for_review",
      header: "Ready for Review",
      meta: { headerAlign: HeaderAlign.RIGHT },
      cell: (info) => (
        <div className="text-right">{info.getValue() as string}</div>
      ),
    },
    {
      accessorKey: "total_review_in_progress",
      id: "total_review_in_progress",
      header: "Review in Progress",
      meta: { headerAlign: HeaderAlign.RIGHT },
      cell: (info) => (
        <div className="text-right">{info.getValue() as string}</div>
      ),
    },
    {
      accessorKey: "total_closed",
      id: "total_closed",
      header: "Closed",
      meta: { headerAlign: HeaderAlign.RIGHT },
      cell: (info) => (
        <div className="text-right">{info.getValue() as string}</div>
      ),
    },
    {
      accessorKey: "total_invoice_pending",
      id: "total_invoice_pending",
      header: "Invoice Pending",
      meta: { headerAlign: HeaderAlign.RIGHT },
      cell: (info) => (
        <div className="text-right">{info.getValue() as string}</div>
      ),
    },
  ];
}
