import React, { useState } from "react";
import Loading from "../shared/loading";
import { useDashboardStats } from "@/services/CallsApiClient";
import Table from "@/components/shared/table/Table";
import { defaultColumns } from "./definitions";
import { format } from "date-fns";
import { type Disposition, type DispositionTable } from "@/types/dashboard";
import { defaultDispositionModel } from "@/services/mock";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";
import { DateStringFormat } from "@/types/callbotRecord";
import { sortingNumberColumn } from "@/components/helpers/table";
import { useRouter } from "next/router";
import { Row } from "@tanstack/react-table";

interface Props {
  selectedDateRange: DatePickerFilter;
}

function Dispositions({ selectedDateRange }: Props): React.JSX.Element {
  const startDate = format(selectedDateRange.startDate, DateStringFormat);
  const endDate = format(selectedDateRange.endDate, DateStringFormat);

  const router = useRouter();
  const [dispositions, setDispositions] = useState<Disposition>(
    defaultDispositionModel
  );
  const [isDataLoading, setIsDataLoading] = useState(true);
  const {
    isLoading,
    isError,
    data: response,
    status,
  } = useDashboardStats(startDate, endDate);
  const memoizedColumns = React.useMemo(() => defaultColumns, []);

  React.useEffect(() => {
    if (status === "success") {
      const { data } = response;
      const dispositions = data.tasks.counters.disposition;
      setDispositions(dispositions);
      setIsDataLoading(false);
    }

    if (isLoading) {
      setIsDataLoading(true);
    }

    if (isError) {
      throw new Error("Error fetching data");
    }
  }, [isError, isLoading, response, status]);

  const getDispositionData = (jsonObject: any) => {
    const dispositionsData: DispositionTable[] = [];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(jsonObject).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      const value = jsonObject[key];

      if (typeof value === "number") {
        dispositionsData.push({
          name: key,
          total: value,
        });
      } else if (!isNaN(Number(value))) {
        dispositionsData.push({
          name: key,
          total: Number(value),
        });
      }
    });

    return dispositionsData.sort((dispositionA, dispositionB) =>
      sortingNumberColumn(dispositionB.total, dispositionA.total)
    );
  };

  const handleRowSelect = () => {
    return;
  };

  const onClickRow = (row: Row<DispositionTable>) => {
    if (row.original?.name) {
      router.push({
        query: { disposition: row.original.name, from: startDate, to: endDate },
        pathname: "/assigned",
      }).catch(e => console.error('Failed to redirect', e));
    }
  };

  const dispositionTableData = getDispositionData(dispositions);

  return (
    <div className="w-full rounded-md bg-slate-100 p-8">
      <div className="flex flex-row justify-between rounded-xl pb-3">
        <p className="tracking-wid mb-4 text-xl font-semibold">Dispositions</p>
      </div>
      {isDataLoading ? (
        <div className="relative h-[345px] w-full rounded-md bg-slate-100 p-8">
          <Loading />
        </div>
      ) : (
        <div className="2xl:gap-7.5 grid h-[535px] grid-cols-1 gap-4 overflow-y-auto xs:grid-cols-1 md:gap-6">
          <Table<DispositionTable>
            data={dispositionTableData}
            columns={memoizedColumns}
            handleRowSelect={handleRowSelect}
            hasGlobalFilterBar={false}
            showPagination={false}
            isSmallTable
            onClickRow={onClickRow}
          />
        </div>
      )}
    </div>
  );
}

export default Dispositions;
