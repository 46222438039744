import React, { useState } from "react";
import Calls from "./calls";
import Tasks from "./tasks";
import Dispositions from "./dispositions";
import Users from "./users";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";

interface Props {
  selectedDateRange: DatePickerFilter;
}

function Dashboard({ selectedDateRange }: Props): React.JSX.Element {
  return (
    <>
      <div className="2xl:mt-7.5 mt-4 md:mt-6">
        <Tasks selectedDateRange={selectedDateRange} />
      </div>
      <div className="2xl:mt-7.5 2xl:gap-7.5 mt-4 grid grid-cols-2 gap-4 xs:grid-cols-1 sm:grid-cols-1 md:mt-6 md:gap-6 xl:grid-cols-2">
        <Calls selectedDateRange={selectedDateRange} />
        <Dispositions selectedDateRange={selectedDateRange} />
      </div>
      <div className="2xl:mt-7.5 mt-4 md:mt-6">
        <Users selectedDateRange={selectedDateRange} />
      </div>
    </>
  );
}

export default Dashboard;
