export const defaultDispositionModel = {
  already_invoiced: 0,
  appeal_follow_up: 0,
  baseline: 0,
  closed_out: 0,
  cola: 0,
  dependent: 0,
  email_follow_up: 0,
  invoiced: 0,
  invoiced_appeal: 0,
  medically_retired: 0,
  new: 0,
  not_tmus: 0,
  over_7k: 0,
  pension_phch31gi_bill: 0,
  ptr_pts: 0,
  retired_lt_50: 0,
  retro_follow_up: 0,
  tdiu_temp_conv: 0,
};

export const defaultCallsModel = {
  "Total Calls": 0,
  Failed: 0,
  "Baseline Not Met": 0,
  "Invoice Opportunity Created": 0,
  "In Progress": 0,
  "Queued": 0,
};

export const defaultTasksModel = {
  closed: "",
  created: "",
  invoice_pending: "",
  ready_for_review: "",
  review_in_progress: "",
};
